import Textbox from '../Textbox';
import { useState, useEffect, useContext, useCallback } from 'react';
import Radio from '../Radio';
import MultipleSelect from '../MultipleSelect';
import { publicAPI, convertCentsToEuro } from '../../utils/utilities';
import { getCountryCodes } from '../../graphql/getCountryCodes';
import { GlobalContext } from '../../pages/Dashboard';

import { isFloatWith2Decimal } from '../../utils/utilities';
import Autocomplete from '../Autocomplete';
import { debounce } from '../../utils/utilities';
import Button from '../Button';
import { FormValidator, PATTERNS } from '../../utils/validator';
import { resellerProducts } from '../../constants/forms';
import { searchResellerAdminByAccessId } from '../../graphql/searchResellerByAccessId';
import { searchResellerByName } from '../../graphql/searchResellerByName';
import { getResellerItem } from '../../graphql/getResellerItem';
import { updateReseller } from '../../graphql/updateResellerAdmin';
import LoadingIndicator from '../LoadingIndicator';
import Alert from '../Alert';
import messages from '../../constants/messages';

const EditReseller = () => {
  const [setUnauthorized] = useContext(GlobalContext);
  const [allCountries, setAllCountries] = useState([]);
  const [isProcessing, setIsProcessing] = useState({ country: true });
  const [isExpandAccessId, setIsExpandAccessId] = useState(false);
  const [isExpandName, setIsExpandName] = useState(false);
  const [keySearchAccessId, setKeySearchAccessId] = useState('');
  const [keySearchName, setKeySearchName] = useState('');
  const [existingReseller, setExistingReseller] = useState<any[]>([]);
  const [apiResponse, setApiResponse] = useState({});

  const [reseller, setReseller] = useState({
    accessId: '',
    name: '',
    product: {},
    priceInCents: 0,
    isRecurring: true,
    secret: '',
    isEmailEditable: true,
    id: '',
    canRequestJoinEmail: false,
    smsEnabled: false,
    emailDomain: [],
    ccJoinEmail: '',
  });
  const [errors, setErrors] = useState({});

  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    addressStreet: '',
    addressStreet2: '',
    addressPostalCode: '',
    addressLocality: '',
    addressRegion: '',
    addressStateCode: '',
    addressCountry: '',
  });

  const onChangeTextboxReseller = (e, fieldName) => {
    setReseller({ ...reseller, [fieldName]: e.target.value });
  };

  const renderListItem = (item) => {
    return <p className="text-base font-base-light">{item.name || item.accessId}</p>;
  };

  const handleOnChangePrice = (e) => {
    const num = e.target && e.target.value.trim();
    if (num && !isFloatWith2Decimal(num)) return false;

    setReseller({
      ...reseller,
      priceInCents: num,
    });
  };

  const handleOutFocus = () => {
    setReseller({
      ...reseller,
      priceInCents: reseller.priceInCents ? Number(reseller.priceInCents).toFixed(2) : 0,
    });
  };

  useEffect(() => {
    publicAPI(
      getCountryCodes,
      ({ data }) => {
        data = data.selectionItemListPromotion.items;
        setAllCountries(data);
        setIsProcessing({
          ...isProcessing,
          country: false,
        });
      },
      undefined,
      { form: { current: { name: 'country_code', items: { value: '' } } } },
      () => setUnauthorized(true)
    );
  }, []);

  const handleSearchResellerByAccessId = async (key) => {
    setReseller({
      ...reseller,
      accessId: key,
    });

    let nextToken;
    let resellers: any[] = [];

    do {
      await publicAPI(
        searchResellerAdminByAccessId,
        ({ data }) => {
          const resellersData = data?.searchResellerByAccessId?.data;
          nextToken = data?.searchResellerByAccessId?.lastEvaluatedKey;
          resellers = resellers.concat(resellersData);
        },
        undefined,
        { accessId: key, lastEvaluatedKey: nextToken },
        () => setUnauthorized(true)
      );
    } while (nextToken);
    setExistingReseller(resellers);
  };

  const handleSearchResellerByName = async (key) => {
    setReseller({
      ...reseller,
      name: key,
    });

    let nextToken;
    let resellers: any[] = [];

    do {
      await publicAPI(
        searchResellerByName,
        ({ data }) => {
          const resellersData = data?.searchResellerByName?.data;
          nextToken = data?.searchResellerByName?.lastEvaluatedKey;
          resellers = resellers.concat(resellersData);
        },
        undefined,
        { name: key, lastEvaluatedKey: nextToken },
        () => setUnauthorized(true)
      );
    } while (nextToken);
    setExistingReseller(resellers);
  };

  const handleSelectReseller = (item) => {
    publicAPI(
      getResellerItem,
      ({ data }) => {
        data = data.getReseller && data.getReseller.reseller;

        const { user, subscriptionInterval, priceInCents = 0, emailDomain = [] } = data;

        setReseller({
          ...data,
          emailDomain: emailDomain.join(';'),
          priceInCents: convertCentsToEuro(priceInCents),
          product: {
            ...resellerProducts.find((item) => item.value === subscriptionInterval),
          },
        });

        setUser({
          ...user,
          addressCountry: user.addressCountry.toUpperCase(),
          lastName: user.familyName,
          firstName: user.givenName,
        });
      },
      undefined,
      { id: item.id },
      () => setUnauthorized(true)
    );

    setIsExpandAccessId(false);
    setIsExpandName(false);

    setKeySearchName(item.name);
    setKeySearchAccessId(item.accessId);
  };

  const debouncedSearch = useCallback(
    debounce((newValue, request, searchField) => request(newValue, searchField), 500),
    []
  );

  const handleErrors = () => {
    const { accessId = '', name = '', product = {}, secret, emailDomain } = reseller;

    const { addressCountry = '', email = '', firstName = '', lastName = '' } = user;
    const errs = {
      accessId: FormValidator(['REQUIRED'], accessId.trim(), 'Access id') || errors.accessId,
      name: FormValidator(['REQUIRED'], name, 'Name'),
      product: FormValidator(['REQUIRED'], product.value, 'Default product'),
      addressCountry: FormValidator(['REQUIRED'], addressCountry, 'Country'),
      email: FormValidator(['REQUIRED', 'EMAIL'], email, 'Email', 'Please enter a valid email'),
      firstName: FormValidator(['REQUIRED'], firstName, 'First name'),
      lastName: FormValidator(['REQUIRED'], lastName, 'Last name'),
      secret: FormValidator(['REQUIRED'], secret, 'Secret key'),
    };

    if (reseller.canRequestJoinEmail) {
      errs.emailDomain = FormValidator(['REQUIRED'], emailDomain, 'Email domain(s)');

      if (!errs.emailDomain && emailDomain.includes('@')) {
        errs.emailDomain = "'@' is not allowed in this field";
      }
    }

    setErrors(errs);

    return errs;
  };

  const handleResetForm = () => {
    setErrors({});
    setReseller({
      accessId: '',
      name: '',
      product: {
        value: '',
        name: '',
      },
      priceInCents: 0,
      isRecurring: true,
      secret: '',
      isEmailEditable: true,
    });

    setUser({
      email: '',
      firstName: '',
      lastName: '',
      addressStreet: '',
      addressStreet2: '',
      addressPostalCode: '',
      addressLocality: '',
      addressStateCode: '',
      addressCountry: '',
    });

    setKeySearchAccessId('');
    setExistingReseller([]);
    setKeySearchName('');
  };

  const handleSubmit = () => {
    setIsProcessing({
      ...isProcessing,
      updateReseller: true,
    });
    const {
      id,
      accessId,
      name,
      product,
      priceInCents,
      secret,
      isEmailEditable,
      isRecurring,
      canRequestJoinEmail,
      emailDomain,
      ccJoinEmail,
    } = reseller;
    const {
      email,
      firstName,
      lastName,
      addressStreet,
      addressStreet2,
      addressPostalCode,
      addressLocality,
      addressStateCode,
      addressCountry,
    } = user;

    const input = {
      id,
      accessId,
      name,
      priceInCents: parseInt(priceInCents * 100),
      isRecurring,
      secret,
      isEmailEditable,
      subscriptionInterval: product?.value,
      email,
      firstName,
      lastName,
      addressStreet: addressStreet || '',
      addressStreet2: addressStreet2 || '',
      addressPostalCode: addressPostalCode || '',
      addressLocality: addressLocality || '',
      addressStateCode: addressStateCode || '',
      addressCountry,
      canRequestJoinEmail,
    };

    if (canRequestJoinEmail) {
      // convert string of emailDomain1;emailDomain2 by array split with ";"
      const emailDomainList = [];
      emailDomain.split(';').forEach((element) => {
        if (element) {
          emailDomainList.push(element.trim().replace('@', ''));
        }
      });

      input.emailDomain = emailDomainList;
      input.ccJoinEmail = ccJoinEmail;
    }

    publicAPI(
      updateReseller,
      ({ data }) => {
        setIsProcessing({
          ...isProcessing,
          updateReseller: false,
        });
        data = data.updateReseller;

        if (data.__typename === 'ErrorResponse') {
          setApiResponse({
            message: data.message,
            type: 'error',
          });

          return;
        }

        if (data) {
          setApiResponse({
            type: 'success',
            message: 'Update reseller successfully',
          });
        }
      },
      undefined,
      { input },
      () => setUnauthorized(true)
    );
  };

  const onOutFocusAccessId = (e) => {
    const key = e.target.value && e.target.value.trim();

    if (key) {
      if (!PATTERNS.ACCESS_ID.test(key)) {
        setErrors({
          ...errors,
          accessId: messages.INVALID_RESELLER_ACCESS_ID,
        });

        return;
      }
    }
  };

  return (
    <>
      {isProcessing.updateReseller && <LoadingIndicator />}
      <Alert
        message={apiResponse.message}
        showAlert={!!apiResponse.message}
        onHideAlert={() => setApiResponse({})}
        type={apiResponse.type}
        showCloseIcon={apiResponse.type === 'error'}
      />
      <p className="font-base-black text-gray-800 leading-tight mt-35 mb-35">Reseller details</p>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Access id <span className="text-error">*</span>:
        </p>
        <Autocomplete
          error={errors.accessId}
          toggleDropdown={setIsExpandAccessId}
          isExpanded={isExpandAccessId}
          onChange={(e) => {
            setErrors({
              ...errors,
              accessId: '',
            });
            const keyEvent = e.target.value;

            setKeySearchAccessId(keyEvent);

            debouncedSearch(keyEvent, handleSearchResellerByAccessId);
          }}
          onSelect={(item) => handleSelectReseller(item, 'accessId')}
          results={existingReseller}
          keySearch={keySearchAccessId}
          // setErrors={handleErrors}
          // selectedOption={currentForm}
          handleOnFocus={() => setIsExpandAccessId(true)}
          renderListItem={(item) => {
            return <p className="text-base font-base-light">{item.accessId}</p>;
          }}
          handleOnBlur={onOutFocusAccessId}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Name <span className="text-error">*</span>:
        </p>
        <Autocomplete
          error={errors.name}
          toggleDropdown={setIsExpandName}
          isExpanded={isExpandName}
          onChange={(e) => {
            const keyEvent = e.target.value;

            setKeySearchName(keyEvent);

            debouncedSearch(keyEvent, handleSearchResellerByName);
          }}
          onSelect={(item) => handleSelectReseller(item, 'name')}
          results={existingReseller}
          keySearch={keySearchName}
          // setErrors={handleErrors}
          // selectedOption={currentForm}
          handleOnFocus={() => setIsExpandName(true)}
          renderListItem={(item) => {
            return <p className="text-base font-base-light">{item.name}</p>;
          }}
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Default product <span className="text-error">*</span>:
        </p>
        <MultipleSelect
          options={resellerProducts}
          setValue={(item) => {
            setReseller({ ...reseller, product: item });
            setErrors({
              ...errors,
              product: '',
            });
          }}
          renderListItem={renderListItem}
          error={errors.product}
          value={reseller.product.name}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Accumulated price <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.priceInCents}
          handleOnChange={(e) => handleOnChangePrice(e)}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.priceInCents}
          handleOnBlur={handleOutFocus}
          disabled
        />
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Recurring <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="isRecurring"
            value={true}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isRecurring: true,
              });
            }}
            checked={reseller.isRecurring === true}
            label={<p>recurring</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="isRecurring"
            value={false}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isRecurring: false,
              });
            }}
            checked={reseller.isRecurring === false}
            label={<p>fixed item</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Secret <span className="text-error">*</span>:
        </p>
        <Textbox
          value={reseller.secret}
          handleOnChange={(e) => onChangeTextboxReseller(e, 'secret')}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.secret}
        />
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Is email editable? <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="isEmailEditable"
            value={reseller.isEmailEditable}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isEmailEditable: true,
              });
            }}
            checked={reseller.isEmailEditable === true}
            label={<p>editable</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="isEmailEditable"
            value={0}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                isEmailEditable: false,
              });
            }}
            checked={reseller.isEmailEditable === false}
            label={<p>not editable</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Can send SMS? <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
              name="smsEnabled"
              value={true}
              handleOnChange={() => {
                setReseller({
                  ...reseller,
                  smsEnabled: true,
                });
              }}
              checked={reseller.smsEnabled === true}
              label={<p>Yes</p>}
              classNames={{
                wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
                checkmark: 'radio-checkmark',
              }}
          />
          <Radio
              name="smsEnabled"
              value={false}
              handleOnChange={() => {
                setReseller({
                  ...reseller,
                  smsEnabled: false,
                });
              }}
              checked={reseller.smsEnabled === false}
              label={<p>No</p>}
              classNames={{
                wrapper:
                    'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
                checkmark: 'radio-checkmark',
              }}
          />
        </div>
      </div>
      <div className="flex items-baseline mb-32 justify-between">
        <p className="font-base-light text-gray-800 leading-tight">
          Can request join email? <span className="text-error">*</span>:
        </p>
        <div className="flex items-baseline w-textbox-lg">
          <Radio
            name="canRequestJoinEmail"
            value={true}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                canRequestJoinEmail: true,
              });
            }}
            checked={reseller.canRequestJoinEmail === true}
            label={<p>Yes</p>}
            classNames={{
              wrapper: 'radio-wrapper font-base-light text-gray-800 leading-tight text-base mr-30',
              checkmark: 'radio-checkmark',
            }}
          />
          <Radio
            name="canRequestJoinEmail"
            value={false}
            handleOnChange={() => {
              setReseller({
                ...reseller,
                canRequestJoinEmail: false,
              });
            }}
            checked={reseller.canRequestJoinEmail === false}
            label={<p>No</p>}
            classNames={{
              wrapper:
                'radio-wrapper font-base-light text-gray-800 leading-tight text-base text-left',
              checkmark: 'radio-checkmark',
            }}
          />
        </div>
      </div>
      {reseller.canRequestJoinEmail && (
        <>
          <div className="flex justify-between items-baseline">
            <p className="font-base-light text-gray-800 leading-tight">
              Email domain(s) <span className="text-error">*</span>:
            </p>
            <Textbox
              value={reseller.emailDomain}
              handleOnChange={(e) => onChangeTextboxReseller(e, 'emailDomain')}
              classNames={{
                wrapper: 'textbox-wrapper mb-32',
                textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
                label: 'text-2lg',
              }}
              error={errors.emailDomain}
            />
          </div>

          <div className="flex justify-between items-baseline">
            <p className="font-base-light text-gray-800 leading-tight">CC join Email:</p>
            <Textbox
              value={reseller.ccJoinEmail}
              handleOnChange={(e) => onChangeTextboxReseller(e, 'ccJoinEmail')}
              classNames={{
                wrapper: 'textbox-wrapper mb-32',
                textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
                label: 'text-2lg',
              }}
              error={errors.ccJoinEmail}
            />
          </div>
        </>
      )}
      <p className="font-base-black text-gray-800 leading-tight mt-50 mb-35">
        Reseller Administrator
      </p>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Email <span className="text-error">*</span>:
        </p>
        <Textbox
          value={user.email}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.email}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          First name <span className="text-error">*</span>:
        </p>
        <Textbox
          value={user.firstName}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.firstName}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Last name <span className="text-error">*</span>:
        </p>
        <Textbox
          value={user.lastName}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          error={errors.lastName}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address street:</p>
        <Textbox
          value={user.addressStreet}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address street 2:</p>
        <Textbox
          value={user.addressStreet2}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address postal code:</p>
        <Textbox
          value={user.addressPostalCode}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address city:</p>
        <Textbox
          value={user.addressLocality}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">Address state code:</p>
        <Textbox
          value={user.addressStateCode}
          classNames={{
            wrapper: 'textbox-wrapper mb-32',
            textbox: 'h-textbox-lg truncate w-textbox-lg pl-18',
            label: 'text-2lg',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between items-baseline">
        <p className="font-base-light text-gray-800 leading-tight">
          Address country <span className="text-error">*</span>:
        </p>
        <MultipleSelect
          options={allCountries.map((item) => ({
            name: item.value,
            id: item.value,
          }))}
          renderListItem={(item) => {
            return <p className="text-base font-base-light">{item.name}</p>;
          }}
          error={errors.addressCountry}
          value={user.addressCountry}
          loading={isProcessing.country}
          disabled
        />
      </div>
      <div className="flex justify-end">
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mr-30 mt-30"
          type="submit"
          handleOnClick={() => {
            const error = handleErrors();
            if (Object.keys(error).some((key) => error[key])) {
              return;
            }
            handleSubmit();
          }}
          disabled={isProcessing.updateReseller}
        >
          Save
        </Button>
        <Button
          classNames=" btn btn-primary w-btn-xs mb-30 mt-30"
          type="submit"
          handleOnClick={() => {
            handleResetForm();
          }}
          disabled={isProcessing.updateReseller}
        >
          Cancel
        </Button>
      </div>
      <div>
        <p className="font-base-black italic">Notes:</p>
        <u className="no-underline list-disc list-inside font-base-light">
          <li>For multiple Email Domain entries, separate values by ';' in text string</li>
        </u>
      </div>
    </>
  );
};

export default EditReseller;
